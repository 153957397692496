@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

:global {
  .animate-enter {
    opacity: 0.01;
    transform: translateX(10%);
  }

  .animate-enter.animate-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 300ms;
  }

  .animate-leave {
    opacity: 1;
    transform: translateX(0);
    transition: all 300ms;
  }

  .animate-leave.animate-leave-active {
    opacity: 0.01;
    transform: translateX(10%);
  }
}

.portfolioBioContainer {
  box-shadow: $box-shadow;
}

.inputs {
  width: 50%;
  display: inline-block;
}

.dayRateContainer {
  width: 50%;
  display: inline-block;
  padding-left: 10px;

  .dayRate {
    width: 100%;
  }
}

.verificationContainer {
  margin-bottom: 20px;
}

@media all and (min-width: $screen-md) {
  .verificationContainer {
    margin-bottom: 0;
  }
}

.displayForMobile {
  display: none;

  @media only screen and (max-width: 992px) {
    display: block;
  }
}

// :global {
//   .jimdo-btn-container > button {
//     color: #fff;
//     background-color: #40a0f5;
//     border-color: #40a0f5;
//     display: inline-block;
//     margin-bottom: 0;
//     font-weight: normal;
//     text-align: center;
//     vertical-align: middle;
//     touch-action: manipulation;
//     cursor: pointer;
//     background-image: none;
//     border: 1px solid transparent;
//     white-space: nowrap;
//     padding: 6px 12px;
//     font-size: 14px;
//     line-height: 1.42857;
//     border-radius: 4px;
//     user-select: none;

//     &:active {
//       background-image: none;
//       color: #fff;
//       background-color: #1088f2;
//       border-color: #0c83eb;
//       outline: 0;
//       box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
//     }

//     &:hover {
//       color: #fff;
//       background-color: #1088f2;
//       border-color: #0c83eb;
//       text-decoration: none;
//     }
//   }
// }
