@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.container {
}

.editModal {
  padding: 10px 20px;
}

.oneEducation {
  display: flex;
  justify-content: space-between;

  [id*='edit-'] {
    border: none;
    background-color: white;

    @media screen and (min-width: $screen-lg) {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.oneEducation:hover {
  [id*='edit-'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
    -moz-transition: opacity 250ms ease-in-out;
    -webkit-transition: opacity 250ms ease-in-out;
  }
}

.updateCancel {
  display: flex;
}

.btnUpdateCancel {
  width: 100%;
}

.warning {
  color: red;
}
