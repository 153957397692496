.testimoinalWrapper {
  text-rendering: optimizeLegibility;
  line-height: 1.6;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  margin-bottom: 10px;

  .text {
    font-family: 'Lato';
    font-weight: 400;
  }
}
