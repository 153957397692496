.roleTag {
  margin-right: 10px;
  padding: 8px 12px;
  line-height: 30px;
  border-radius: 5px;
  background-color: #f1f1f1;
  font-size: 12px;
  color: #333333;
}

:hover.roleTag {
  background-color: #cccaca !important;
}
