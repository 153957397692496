.container {
  .mainImage {
    width: 100%;
    position: relative;

    .thumbnail {
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute !important;
    }
  }

  input[type='file'] {
    display: none;
  }

  .button {
    display: block;
    margin: 20px auto 0;
  }
}
