@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

:global {
  .pac-container {
    z-index: 1051 !important;
  }
}

.crossIconContainer {
  position: absolute;
  right: 10px;
  top: 5px;
  display: block;
  z-index: 50;
  transition: opacity 0.25s;
}

input {
  &::placeholder {
    font-size: 16px;
    color: #aaa;
  }
}

.locationInputDefaultContainer {
  display: inline-block;
}
