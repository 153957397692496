.bar {
  width: 100%;
  border-radius: 3px;
  background-color: #d8dee3;
  height: 25px;
}

.barInside {
  border-radius: 3px;
  text-align: right;
  height: 100%;
  font-size: 13;
}

.barInnerText {
  padding: 2px 5px 0px 5px;
}
