@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.bioPanel {
  overflow: visible;
}

.bioPanel:hover {
  [id*='edit-'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
    -moz-transition: opacity 250ms ease-in-out;
    -webkit-transition: opacity 250ms ease-in-out;
  }
}

.pitchFade {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: -webkit-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -moz-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -o-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -ms-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.bio {
  margin-top: 20px;
}

.bio p {
  font-size: 15px;
}

.datePickerContainer {
  width: 100%;
  max-width: 250px;
}

.editModal {
  padding: 10px 20px;
}

.ad {
  background-color: #f4ede3;
  border-radius: 4px;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 20px;
}
