@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.modalContainer {
  padding: 10px;

  @media (max-width: $screen-sm) {
    padding: 0;
  }
}

.headerContainer {
  .headerLabel {
    font-weight: 700;
    text-align: center;
    margin: 10px 0;
  }
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  .navIconContainer {
    display: flex;
    align-items: center;

    font-size: 14px;
  }

  .navStepCount {
    font-weight: 700;
    font-size: 14px;

    @media (max-width: $screen-sm) {
      display: none;
    }
  }

  .navLabel {
    display: inline-flex;
    align-items: center;
    line-height: 14px;
    padding: 0 2px;
  }

  .navButton {
    min-height: 35px;
    min-width: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navButtonPlaceholder {
    min-height: 35px;
    min-width: 100px;
  }
}

.stepContainer {
  margin: 30px 0;
  padding: 20px 15px;

  @media (max-width: $screen-sm) {
    margin: 15px 0;
    padding: 10px 7px;
  }

  .stepImage {
    width: 100%;
  }
}

:global {
  .animation-fadein-v1-appear,
  .animation-fadein-v1-enter,
  .animation-fadein-v1-exit {
    opacity: 0;
  }

  .animation-fadein-v1-appear-active,
  .animation-fadein-v1-enter-active,
  .animation-fadein-v1-exit-active {
    opacity: 1;
    transition: all 1s;
  }

  .animation-fadein-v1-appear-done,
  .animation-fadein-v1-enter-done,
  .animation-fadein-v1-exit-done {
    opacity: 1;
  }
}
