@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.portfolioCover {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: flex-end;

  height: em-calc(215);
  width: inherit;
  border-radius: 0;
  max-height: 100%;
  float: left;
  top: -0em;

  @media (max-width: $screen-md) {
    height: max-content;
    padding-bottom: 1em;
  }

  @media (min-width: $screen-md) {
    height: em-calc(365);
  }

  @media (min-width: $screen-lg) {
    height: em-calc(365);
  }
}

.coverContent {
  display: flex;
  flex-direction: column;
  align-self: center;

  h1 {
    margin: 0;
  }

  > div {
    margin-bottom: 2em;
    &:last-child {
      margin-bottom: 0em;
    }
  }

  @media (max-width: $screen-xs) {
    align-self: flex-start;
    margin: 3% 0 0 5%;
    width: 100%;
  }
  @media (min-width: $screen-xs) {
    width: 95%;
  }
  @media (min-width: $screen-sm) {
    width: 714px;
  }
  @media (min-width: $screen-md) {
    width: 970px;
  }
  @media (min-width: $screen-lg) {
    width: 1170px;
  }

  .topRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .bottomRow {
    display: flex;
    flex-direction: row;
    z-index: 1;
    justify-content: space-between;

    .buttonContainer {
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      flex-basis: auto;

      > button {
        margin-right: 1em;
        &:only-child,
        &:last-child {
          margin-right: 0em;
        }
      }
      @media (max-width: $screen-xs) {
        display: none;
      }
    }
  }
}

.carouselButton {
  display: block;
  height: 40px;
  background-color: #999;
  overflow: hidden;
  position: absolute;
  border-radius: 20px;
  padding: 5px;
}

.bioSnippet {
  color: #fff;
  text-transform: none;
  text-align: left;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

  width: 50%;
  @media (max-width: $screen-xs) {
    display: none;
  }
}

.xsPanel {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px;
  overflow: visible !important;

  > div,
  > h3 {
    margin-bottom: 1em;
    &:only-child,
    &:last-child {
      margin-bottom: 0em;
    }
  }
  @media (min-width: $screen-xs) {
    display: none;
  }
  .bioSnippetXS {
    color: black;
    flex: 0.8;
    text-align: left;

    @media (min-width: $screen-xs) {
      display: none;
    }
    @media (max-width: $screen-xs) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }
  }

  .buttonContainerXS {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    flex-basis: auto;
    flex-grow: 0.05;
    justify-content: space-between;

    @media (min-width: $screen-xs) {
      display: none;
    }
    @media (max-width: $screen-xs) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }
  }
}

.displayname {
  display: none;
  color: #fff;
  font-weight: 700;
  padding: 0 5px;
  margin: 0;
  font-size: 16px;
  vertical-align: top;
  line-height: 30px;
  text-transform: none;

  @media (min-width: $screen-md) {
    display: inline-block;
  }
}

.avatar {
  width: 30px;
  height: 30px;
  display: inline-block;
}

.chevron {
  display: inline-block;
  position: relative;
  top: 8px;
  vertical-align: top;
}

.next {
  right: 10px;
}

.previous {
  left: 10px;
}

// .editButton {
//   border-color: #fff;
//   background-color: $brand-purple;
//   -webkit-transition: all 0.15s ease-in;
//   transition: all 0.15s ease-in;

//   &:hover {
//     background-color: #534587;
//   }
// }
