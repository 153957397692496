@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 1;

  @media (max-width: $screen-xs) {
    width: 95%;
  }
}

.nameContainer {
  display: flex;
  flex-direction: row;
  min-height: 40px;
}

.shared {
  color: #fff;
  text-align: left;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: $screen-sm) {
    // font-size: calc(50% + 2vw);
  }
}

.item {
  @extend .shared;
  font-size: 20px;
}

.rating {
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.displayName {
  @extend .shared;
  font-size: 30px;
  font-weight: 700;
  text-transform: none;
  line-height: 36px; /* or 120% */
}

.lastSeen {
  margin-top: 1px;
}
