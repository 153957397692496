@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.initialHireContainer {
  margin: 0 15px;

  @media screen and (max-width: $screen-sm) {
    margin: 0;
  }

  .infoText {
    color: #888;
    margin: 15px 30px;
    text-align: center;
    font-size: 14px;

    @media screen and (max-width: $screen-sm) {
      margin: 10px 10px 5px 10px;
    }
  }

  .termsCheckboxes {
    margin: 30px;
    @media screen and (max-width: $screen-sm) {
      margin: 15px;
    }
  }

  .ctaButtons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: row;

    @media screen and (max-width: $screen-md) {
      flex-direction: column;
      .ctaButton {
        margin-top: 5px;
      }
    }
  }
}

.quotedAmountContainer {
  margin-bottom: 15px;
}

.ctaButtonContainer {
  display: flex;
  justify-content: space-evenly;
}

.ctaButton {
  font-size: 16px;

  display: flex;
  min-width: 180px;
  align-self: center;
  align-items: center;
  justify-content: center;

  .ctaButtonTextIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.paymentOptionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .radioButtonsContainer {
    margin: 10px 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.totalAmountContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .totalAmountQuestion {
    display: flex;
    justify-content: center;
  }

  .totalToPayContainer {
    font-size: 18px;
    margin: 5px 0 5px 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .totalToPayLabel {
      margin: 5px 0;
      font-size: 16px;

      display: flex;
      font-weight: bold;
      justify-content: center;
    }

    .totalToPayValue {
      margin: 10px 0;
      font-size: 24px;

      display: flex;
      justify-content: center;
    }
  }
}

.errorTextContainer {
  margin: 0 0 15px 0;

  display: flex;
  justify-content: center;

  .errorText {
    color: $brand-secondary;

    opacity: 0;
    will-change: opacity;
    transition: 200ms all ease;

    text-align: center;
  }

  .showErrorText {
    opacity: 1;
  }
}

:global(#total-to-pay-input) {
  margin: 0 auto;
  max-width: 300px;

  &:focus {
    outline: auto 1px;
    box-shadow: none;
  }
}
