.inputs {
  width: 50%;
  max-width: 120px;
  display: inline-block;
}

.dayRateContainer {
  width: 50%;
  max-width: 120px;
  display: inline-block;
  padding-left: 10px;

  .dayRate {
    width: 100%;
  }
}

.error {
  font-size: 14px;
  color: red;
}
