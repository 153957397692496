@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.steps {
  overflow: hidden;
  text-align: center;
  display: inline-block;
  zoom: 75%;

  @media screen and (min-width: $screen-sm) {
    zoom: 100%;
  }
}

.greyDot {
  height: 8px;
  width: 8px;
  border-radius: 1000px;
  background-color: #ccc;
  display: inline-block;
  margin: 35px 4px;

  @media screen and (min-width: $screen-sm) {
    height: 10px;
    width: 10px;
  }
}

.stepText {
  text-align: center;
  vertical-align: text-top;
  display: inline-block;
  width: 80px;
  margin: 10px 30px 0;
  font-weight: 700;
  white-space: normal;
  color: white;

  @media screen and (min-width: $screen-sm) {
    width: 125px;
    margin: 10px 50px 0;
  }
}

.stepTextLeft {
  text-align: center;
  vertical-align: text-top;
  display: inline-block;
  width: 80px;
  margin: 10px 15px 0 -30px;
  font-weight: 700;
  white-space: normal;
  color: white;

  @media screen and (min-width: $screen-sm) {
    width: 125px;
    margin: 10px 50px 0 -50px;
  }
}

.stepTextRight {
  text-align: center;
  vertical-align: text-top;
  display: inline-block;
  width: 80px;
  margin: 10px -30px 0 15px;
  font-weight: 700;
  white-space: normal;
  color: white;

  @media screen and (min-width: $screen-sm) {
    width: 125px;
    margin: 10px -50px 0 50px;
  }
}
