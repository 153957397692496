@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.skillContainer {
  text-align: center;
  margin-bottom: 5px;
  overflow-wrap: break-word;
  width: 70px;
  font-size: 10px;
  color: #666;
}

.skill {
  text-align: center;
  display: flex;
  height: 70px;
  width: 70px;
  border-radius: 5px;
  margin: 0px 0px 3px 0px;
  background: #eeeeee;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skillEmpty {
  height: 70px;
  width: 70px;
  border-radius: 5px;
  margin: 3px;
  margin-bottom: 10px;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.noLogo {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #363c6e;
  font-weight: 700;
  color: #f1f1f1;
  font-size: 30px;
}
