.select {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: 35px;
    background: #ccc;
  }

  :global {
    select {
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      color: #555555;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      appearance: none;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      background-image: url('./arrow.svg');
      background-origin: content-box;
      background-position: 100%;
      background-repeat: no-repeat;
      background-size: 9px 6px;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;

      &:focus {
        outline: none;
        background-color: #fefefe;
        box-shadow: 0 0 5px #cacaca;
      }
    }
  }
}
