@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.portfolioAdPanel {
  -moz-box-shadow: $box-shadow;
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
  @media screen and (max-width: $screen-md) {
    height: 255px;
    margin-top: 20px;
  }

  .advertContainer {
    display: flex;
    flex-direction: column;

    .advertHeader {
      width: 100%;

      display: flex;
      flex-direction: row;

      .companyLogo {
        display: flex;
        align-items: center;

        @media screen and (max-width: $screen-xs) {
          img {
            width: 100%;
          }
        }
      }

      .companyName {
        margin-left: 10px;
        font-size: 2rem;
      }

      .nextAdvert {
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        align-self: center;
        color: $humility;
        transition: color 0.3s;
        &:hover {
          color: $brand-primary;
          &::after {
            content: 'Next';
            position: absolute;
            right: 45px;
          }
        }
      }

      .prevAdvert {
        height: 24px;
        cursor: pointer;
        align-self: center;
        margin-right: 10px;
        color: $humility;
        transition: color 0.3s;
        &:hover {
          color: $brand-primary;
        }
      }
    }

    .advertDescription {
      margin: 14px 30px 0px 30px;
      flex: 1;
      font-size: 14px;
      @media screen and (max-width: $screen-xs) {
        margin: 10px 20px;
      }
      p {
        margin-bottom: 14px;
        font-size: 14px;
      }
    }
  }

  .whiteText {
    color: white;
  }

  .advertCTA {
    font-size: 14px;
    text-decoration: underline;
    font-weight: bold;
  }

  .CTAChevron {
    margin-bottom: -1px;
  }

  .partnerLogoContainer {
    height: 50px;
    width: 200px;
    display: flex;
    align-content: center;
  }
  .partnerLogo {
    max-height: 100%;
    max-width: 100%;
    align-self: center;
  }

  .lazyLoadFlex {
    display: flex;
  }
}
