@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.experienceRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px;
}

.rangeSliderContainer {
  width: 100%;
  display: flex;
}

.deleteButton {
  margin-left: 10px;
  margin-top: -7px;
  cursor: pointer;
}

.skillName {
  width: 50%;
  padding: 2px;
}

.black {
  color: black;
}

.greyedOut {
  color: #d8dee3;
}
