@import 'src/theme/variables.scss';

.container {
  display: inline-block;
  border-radius: 1000px;
  padding: 2px 8px;
}

.clickable {
  cursor: pointer;
}

.primary {
  background-color: $brand-primary;
  color: #fff;

  &.inverse {
    background-color: transparent;
    color: $brand-primary;
    border: 1px solid $brand-primary;
  }
}

.success {
  background-color: $brand-success;
  color: #fff;

  &.inverse {
    background-color: transparent;
    color: $brand-success;
    border: 1px solid $brand-success;
  }
}

.danger {
  background-color: $brand-danger;
  color: #fff;

  &.inverse {
    background-color: transparent;
    color: $brand-danger;
    border: 1px solid $brand-danger;
  }
}

.info {
  background-color: $brand-info;
  color: #fff;

  &.inverse {
    background-color: transparent;
    color: $brand-info;
    border: 1px solid $brand-info;
  }
}

.secondary {
  background-color: $brand-secondary;
  color: #fff;

  &.inverse {
    background-color: transparent;
    color: $brand-secondary;
    border: 1px solid $brand-secondary;
  }
}

.darkgrey {
  background-color: $brand-darkgrey;
  color: #fff;

  &.inverse {
    background-color: transparent;
    color: $brand-darkgrey;
    border: 1px solid $brand-darkgrey;
  }
}

.transparent {
  background-color: transparent;
  color: black;
}

.purple {
  background-color: $brand-accent-purple;
  color: #fff;

  &.inverse {
    background-color: transparent;
    color: $brand-accent-purple;
    border: 1px solid $brand-accent-purple;
  }
}

.white {
  background-color: white;
  color: black;

  &.inverse {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
  }
}

.black {
  background-color: black;
  color: white;

  &.inverse {
    background-color: transparent;
    color: black;
    border: 1px solid black;
  }
}
