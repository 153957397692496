@import 'src/theme/variables.scss';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.creditsList {
  white-space: nowrap;
  overflow: hidden;
  height: 58px;
}

.creditAvatar {
  width: 35px;
  height: 35px;
  display: inline-block;
}

.coverImage {
  width: 100%;
  @media screen and (min-width: $screen-lg) {
    height: 240px;
  }
  @media screen and (min-width: $screen-xl) {
    height: 290px;
  }
}

.projectCard {
  border-radius: 4px;
  -moz-box-shadow: $box-shadow;
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}
