.sliderContainer {
  width: 100%;
}

.slider {
  border-radius: 8px;
  height: 7px;
  width: 356px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.slider::-webkit-slider-thumb {
  // -webkit-appearance: none; allows custom styling
  // for the html input type range
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #2cae76;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #2cae76;
  cursor: pointer;
}
