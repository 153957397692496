@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.more {
  margin-top: 10px;
  color: #0777da;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.freelanceGig {
  margin-bottom: 10px;
}

.more:hover {
  text-decoration: underline;
  filter: brightness(85%);
  cursor: pointer;
}
