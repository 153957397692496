@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.container {
  display: flex;
  padding-left: 2em;
  width: 50%;
  flex-direction: column;
}

.nameContainer {
  display: flex;
  flex-direction: row;
}

.selectorContainer {
  display: flex;
  flex-basis: auto;
  min-height: 40px;
}

.addRoles {
  background: $brand-primary;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: normal;
  background-color: $brand-primary;
  border: 1px solid transparent;
  border-color: $btn-primary-border;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
}

.addRoles:hover {
  filter: brightness(85%);
}
