@import 'src/theme/variables.scss';
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.header {
  font-size: 15px;
  position: relative;
  font-weight: 700;
  color: #555;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.editBtn {
  border: none;
  background-color: none;
  background: transparent;
  margin-top: -0.5em;
}

.editWrp {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  border: none;
  position: relative;
  visibility: visible;
  max-height: 33px;
}

.panel {
  padding: 10px 20px 20px !important;
  overflow: visible !important;

  -moz-box-shadow: $box-shadow;
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;

  [id*='edit-'] {
    border: none;
    background-color: none;
  }

  .loader {
    img {
      height: 20px;
      width: 20px;
    }
  }
}

.inHeader {
  // max-width: 25%;
  overflow: visible;
  display: flex;
  flex-basis: auto;

  // [class*='editBtn'] {
  [id*='edit-'] {
    border: none;
    background-color: none;
  }

  .loader {
    img {
      height: 20px;
      width: 20px;
    }
  }
}
