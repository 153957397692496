@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
@import 'src/theme/variables.scss';

.displayForDesktop {
  display: block;

  @media only screen and (max-width: 992px) {
    display: none;
  }
}

.panel {
  -moz-box-shadow: $box-shadow;
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

.panelChild {
  padding: 25px 0 40px;
}

.panelChild:nth-child(n) {
  border-bottom: 1px solid #ddd;
}
.panelChild:last-child {
  border-bottom: none;
}

.desktopOnly {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.visitorPanelText {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.roleLinks {
  margin-top: 20px;
}
